import styled from "styled-components";

const $DropoffMethods = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .recommended-options > .header, .other-options-header {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;

    h2 {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  .other-options-header {
    margin-bottom: 12px;
  }
  .recommended-options {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    svg[name="leaf"] {
      margin-right: 8px;
    }
    .header {
      margin-right: 8px;
    }
    a.more-info {
      font-size: 14px;
      line-height: 20px;
      color: var(--header-grey);
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .label {
    display: flex;
    align-items: center;
  }
  .dropoff-card {
    @media (hover: none) and (pointer: coarse) {
      &:focus {
        outline: none;
      }
    }

    display: flex;
    flex-direction: column;
    align-items: unset;
    width: 100%;
    border: 1px solid var(--border-grey);
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    &.selected {
      align-items: unset;
      border: 2px solid var(--text-black);
      .dropoff-header, .dropoff-price {
        font-weight: 800;

        h3 {
          font-size: 15px;
          font-weight: 800;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }


      :hover {
        border: 2px solid var(--text-black);
      }
    }
    &.first {
      padding: 15px;
    }

    :hover {
      border: 1px solid var(--text-black);
    }

    .dropoff-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: start;
      width: 40px;
      height: 40px;
      margin-right: 16px;
      flex-shrink: 0;
      background-color: #F8F8F8;
      border-radius: 50%;
      svg {
        max-width: 40px;
        max-height: 40px;
      }
    }
    .dropoff-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      & > div {
        margin: 2px 0;
      }
      .content-main {
        display: flex;
        .dropoff-header, .dropoff-price {
          color: var(--text-black);
          font-size: 15px;
          line-height: 22px;

          h3 {
            color: var(--text-black);
            font-size: 15px;
            margin-block-start: 0;
            margin-block-end: 0;
          }
        }
        .dropoff-header {
          padding-right: 8px;
        }
        .dropoff-price {
          margin-left: auto;
          display: flex;
          align-items: center;
          svg[name="leaf"]:not(.no-fee){
            margin-right: 12px;
          }
        }
      }
      .dropoff-text {
        font-size: 14px;
        line-height: 20px;
        color: var(--header-grey);
      }
      .dropoff-link {
        display: flex;
        margin-top: 8px;
        .pre-text {
          margin-right: 12px;
        }
        a, .pre-text {
          font-size: 14px;
          line-height: 20px;
          color: var(--text-black);
        }
      }
      .location {
        padding: 12px 0;
        border-bottom: 1px solid var(--border-grey);
        a {
          color: var(--header-grey);
        }
        .location-main {
          display: flex;
          justify-content: space-between;
          .name {
            font-size: 15px;
            line-height: 22px;
            color: var(--text-black);

            h4 {
              font-size: 15px;
              font-weight: 400;
              line-height: 22px;
              color: var(--text-black);
              margin-block-start: 0;
              margin-block-end: 0;
            }
          }
          .distance {
            color: var(--header-grey);
            font-size: 14px;
            line-height: 20px;
            text-decoration-line: underline;
          }
        }
        .location-status {
          font-size: 14px;
          line-height: 20px;
          padding-bottom: 8px;
          color: var(--header-grey);

          .toggle-details {
            margin-left: 12px;
            display: inline;
            text-decoration: underline;
            svg {
              margin-left: 4.8px;
            }
            border: 0;
            padding: 0;
            background: none;
            font-family: "sofia-pro", sans-serif;
            font-size: 14px;
            color: var(--header-grey);
          }
        }
        .icon-column {
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
        .location-details {
          display: flex;
          padding-bottom: 8px;
          color: var(--header-grey);
          .display {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .location-promotion {
          display: flex;
          align-items: center;
          color: var(--text-black);
          font-size: 14px;
          line-height: 20px;
        }
      }
      .location:last-child {
        padding-bottom: 0;
        border-bottom: none
      }
    }
    .dropoff-radio {
      margin-left: 12px;
      display: flex;
      align-items: center;
      label {
        position:absolute;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
      }
      input[type="radio"] {
        margin: 0;
        width: 16px;
        height: 16px;
      }
    }
  }
  .carrier-option {
    @media (hover: none) and (pointer: coarse) {
      &:focus {
        outline: none;
      }
    }
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid var(--border-grey);
    &.selected {
      .option-content-main {
        font-weight: 800;
      }
    }
    .option-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > div {
        margin: 2px 0;
      }
      .option-content-main {
        display: flex;
        .option-header {
          color: var(--text-black);
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
    .dropoff-radio {
      margin-left: auto;
    }
    :last-child {
      padding-bottom: 0;
      border-bottom: none
    }
  }
  button.expand-options {
    font-family: "sofia-pro", sans-serif;
    width: 164px;
    min-height: 48px;
    background: #FFFFFF;
    border: 1px solid #A2A2A2;
    box-sizing: border-box;
    border-radius: 12px;
    align-self: center;
    cursor: pointer;

    :hover {
      border: 1px solid var(--text-black);
    }
  }
`;

export const $DropoffMethodsContents = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  .header {
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    line-height: 26px;
    color: var(--text-black);
  }
  .message {
    font-size: 15px;
    line-height: 22px;
    color: var(--header-grey);
    margin-top: 8px;
  }
  .banner {
    margin-top: 24px;
    margin-bottom: 24px;
    // ErrorAlertMessage
    & > div {
      align-items: baseline;
    }
  }
  button {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export default $DropoffMethods
