import React from "react";

import { SVG } from "../../components/svg";
import { getAttributeValue, handleEnterKeyPress, handleEnterKeyPressOnClickHandlers, isSelectedForReturn } from "../../utility";
import { Instance } from "../../types/Instance";

import { $InstanceButton } from "./styles";
import { StringMap } from "../../types/StringMap";
import ItemDetails from "../ItemDetails";
import { AppRuntimes } from "../../types/AppRuntimes";

import getTranslator from "../../utility/getTranslator";
import { DataCyStrings } from "../../types/DataCyStrings";
import useSelector from "../../utility/useTypedSelector";
import ga from "../../utility/GAEmitter";
import { AnalyticCategories, ItemListPageActions } from "../../types/Analytics";

const useTranslation = getTranslator('InstanceButton')

interface InstanceButtonProps {
  item: Instance,
  currentBrand: string,
  currentOrder?: string,
  singleOrderReturns?: boolean,
  runtime: string,
  isSelected?: boolean,
  isDisabled?: boolean,
  isDropship: boolean,
  vendors?: string[],
  onClick: () => any,
  onDeselect?: () => any,
  onZoom?: () => any,
  retailerOptionLabels?: StringMap,
  dataCyString?: DataCyStrings
}

/**
 * Displays item information including the thumbnail
 *
 * @props item         - the instance to be displayed
 * @props currentBrand - the currentBrand selected for a new return. If not set it will be an empty string.
 * @props currentOrder - the order number for the current return. If not set it will be an empty string.
 * @props singleOrderReturns - used to limit returns to a single order.
 * @props isSelected   - used to track the instances to be processed/refunded
 * @props onClick      - callback that triggers when the instance card is clicked
 * @props onDeselect   - optional callback that when defined, this component renders
 *                       an X on the top right of the instance and is triggered when
 *                       the X is clicked
 * @props onZoom       - triggers when the magnifying glass SVG is clicked
 * @props isDropship   - when true, display the item's brand name
 */
const InstanceButton = ({ item, currentBrand, currentOrder, singleOrderReturns, runtime, isSelected, isDropship, vendors, onZoom, onClick, onDeselect, retailerOptionLabels, dataCyString }: InstanceButtonProps) => {
  const { returnShoppingEnabled } = useSelector((store) => store.app);
  const instanceBrandName = item?.purchase?.vendor || "default";
  const instanceName = item?.purchase?.orderNumber;
  let isDisabled = false;


  if (currentBrand && isDropship && instanceBrandName.toLowerCase() != currentBrand.toLowerCase() && vendors?.length) {
    // disable the instance if either instanceBrand or the currentBrand which was already selected
    // is part of the RSSO vendors list
    isDisabled = vendors.includes(currentBrand.toLowerCase()) || vendors.includes(instanceBrandName.toLowerCase());
  }

  if (singleOrderReturns && currentOrder && instanceName != currentOrder) {
    isDisabled = true;
  }
  const priceDisplay = runtime === AppRuntimes.returnPortal ? "inline" : undefined;
  const { t } = useTranslation();


  const optionIDToLabel = {
    "refund": t('refund'),
    "exchange": t('exchange'),
    "replacement": t('replacement'),
    "store-credit":t('storeCredit')
  }

  const returnShoppingBadges = {
    returning: t("returning"),
    exchange: t('exchanging'),
    replacement: t('replacing'),
  };

  const shouldDisplayItemStatus = () => {
    return (item.purchase?.returnability && item.purchase?.returnable != 1) ||
      isSelected ||
      isDisabled;
  }
  const shouldRenderDisabled = () => {
    // items that are selected could have been overrides
    // they should no longer be rendered disabled
    if (isSelected)
      return false

    // if not selected, use normal rendering mechanism
    return (item.purchase?.returnability && item.purchase?.returnable != 1) || isDisabled;
  }
  const renderItemStatus = () => {
    let optionID = item?.refund?.optionID || item?.refund?.id;
    // set the optionID to "returning" to handle case of user changing return options later
    if (isSelectedForReturn(item) && returnShoppingEnabled) {
      optionID = "returning"
    }
    // first we check if the sku matches between the original purchase, and the exchange item
    let isReplacement = optionID === "exchange" && item?.purchase?.sku === item?.refund?.exchange?.sku;

    // then we want to ensure that the attributes match up. ei: if the user requests a size up
    // on an item, they can potentially have the same SKU but because the size attribute
    // is different, the return doesn't count as a replacement but is considered an exchange.
    // if all the exchange attributes match with the original attributes, then the return is a replacement
    if (isReplacement) {
      for (let displayAttr of item?.purchase.display) {
        let attrVal = item?.refund?.exchange ? getAttributeValue(item?.refund?.exchange, displayAttr.label) : null;
        // compare value from exchange item to
        if (attrVal && attrVal !== displayAttr.value) {
          // only compare the value of an attr if it is found
          // in both purchase and refund exchange
          isReplacement = false;
          break;
        }
      }
      // check if the item is still considered a replacement
      if (isReplacement) {
        optionID = "replacement";
      }
    }

    const retailerOptionLabel = retailerOptionLabels?.[optionID];
    // if return shopping is enabled use the return shopping badges
    // otherwise use the standard "RETURNING FOR" badges
    const returningFor =
      (returnShoppingEnabled && returnShoppingBadges[optionID]) ||
      (retailerOptionLabel
        ? t("returningFor", { returningFor: retailerOptionLabel })
        : optionIDToLabel?.[optionID] || t("returning"));

    if (isSelected) {
      return (
        <div className="returning-for" data-cy={DataCyStrings.returningFor}>
          {returningFor}
        </div>
      )
    } else if (item.purchase?.returnability && item.purchase?.returnable != 1) {
      return (
        <div className="option-disabled" data-cy={DataCyStrings.optionDisabled}>
          <div>
            {item.purchase?.returnability}
          </div>
        </div>
      )
    } else  if (isDisabled) {
      return (
        <div className="option-disabled" data-cy={DataCyStrings.optionDisabled}>
          <div>
            {t('separateReturnNeeded')}
          </div>
        </div>
      )
    }
  }

  const onZoomHandlers = handleEnterKeyPressOnClickHandlers((e) => {
    e?.stopPropagation?.();
    ga.event({
      category: AnalyticCategories.ItemListPage,
      action: ItemListPageActions.MagnifyingGlass
    })
    onZoom && onZoom();
    e?.preventDefault?.();
  });

  const onDeselectHandlers = handleEnterKeyPressOnClickHandlers(() => {
    if (onDeselect) {
      onDeselect();
    }
  });
  return (
    <$InstanceButton
      key={item.id}
      selected={isSelected}
      onClick={() => {
        !isDisabled && onClick()
      }}
      tabIndex={0}
      onKeyUp={(e) => handleEnterKeyPress(e, () => onClick())}
      shouldDisplayItemStatus={!!shouldDisplayItemStatus()}
      shouldRenderDisabled={!!shouldRenderDisabled()}
      data-cy={dataCyString}
    >
      <div className="thumbnail" data-cy={DataCyStrings.itemThumbnail}>
        <img src={item?.purchase?.thumbnail} alt=""/>
        {onZoom &&
          <SVG name="zoom" {...onZoomHandlers} aria-label={t('showItemDetails')}/>
        }
      </div>
      <div className="item-details-container">
        <ItemDetails item={item} runtime={runtime} priceDisplay={priceDisplay} showSku showVendor={isDropship}/>
      </div>
      <div className="item-status" data-cy={DataCyStrings.instanceItemStatus}>
        {onDeselect && isSelected &&
          <div className="deselect" data-cy={DataCyStrings.deselect} {...onDeselectHandlers}>
          <SVG role="application" name="x" aria-label={t('deselect')} title={t('deselect')} data-cy={DataCyStrings.deselectSvgX}/>
          </div>
        }{!onDeselect &&
          <div className="check">
          <SVG name="check" aria-label={isSelected ? t('selectedAlt') : t('notSelectedAlt')} data-cy={DataCyStrings.checkmarkSvg}/>
          </div>
        }
        {renderItemStatus()}
      </div>
    </$InstanceButton>
  );
};

export default InstanceButton;
