// third-party imports
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { cloneDeep } from "lodash";

// repo imports
import ReturnCountFooter from "../../components/ReturnCountFooter";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { PageLifecycle } from '../';
import { PageProps } from "../types";
import { RootReducer } from "../../redux/reducers";
import InstanceButton from "../../components/InstanceButton";
import { Instance } from "../../types/Instance";
import { containsReturn, getReadableTimestamp, jwtClaimsByRuntime } from "../../utility";
import { ReturnPortalBaseWithPage } from "../../components/ReturnPortalBase";
import InstanceDetailsModal from "../../components/Modal/InstanceDetailsModal";
import {
  setSelectedInstance,
  deselectInstanceWithID,
  fetchOrders,
  setSelectedDropoffMethod,
  setPurchasing
} from "../../redux/customer/actions";
import logger from "../../utility/logger";

// local imports
import $OrderList, { $OrderListReturnPortal } from  "./styles";
import { Store as CustomerStore } from "../../redux/customer/types";
import { InfoMessage } from "../../components/AlertMessage";
import { handlePortalPurchasesError } from "../../redux/app/actions";
import { AnalyticCategories, AnalyticsPageRoutes, CommonPageActions, ItemListPageActions } from "../../types/Analytics";
import ReturnOverrideModal from "../../components/Modal/ReturnOverrideModal";
import { getItemIsReturnableFn, getCustomerEmailFromPurchases } from "./util";
import { AppRuntimes } from "../../types/AppRuntimes";
import getTranslator from "../../utility/getTranslator";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";
import { statsigClient } from "../../utility/useStatsigClient";
import { $ScreenReaderOnlyComponent } from "../ScreenReaderOnlyComponent/styles";

const useTranslation = getTranslator('OrderList');

class OrderListLifecycle extends PageLifecycle {
  customer:CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app)
    this.customer = customer;
  }
}

/**
 * Displays a list of orders associated to an order number or email
 * whereas the instance list page is used for already started returns
 * that are associated with express codes
 */
const OrderList = ({ page }: PageProps) => {
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const { customer, app } = useSelector((store: RootReducer) => store);
  const { runtime, returnShoppingEnabled } = app;
  const { itemsMarkedForCompletion } = customer;
  const {
    copies,
    locale
  } = useSelector((store) => store.app);
  const lifecycle = new OrderListLifecycle(page, dispatch, app, customer);
  const [instanceDetailsModalInstance, setInstanceDetailsModalInstance] = useState<Instance | undefined>(undefined);

  // necessary for async/await modal instead of callback or state hell
  const [fulfillOverride, setFulfillOverride] = useState<((boolean) => void) | undefined>(undefined)
  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false)
  const retailerName = copies?.retailerName
  const claims = (runtime && app.token) ? jwtClaimsByRuntime[runtime]?.(app.token) : {
    returnsApp: {}
  }

  // current return bar is an in-store retailer
  // we only allow overrides for in-store retailers
  const isRetailer = Boolean(claims?.returnsApp?.retailerID);

  // admin mode is set by the Retailer Dashboard to provide admin overrides in the Return Portal
  const isAdminMode = Boolean(claims?.returnsApp?.adminMode);

  const { t } = useTranslation();


  const errorCopies = {
    400: t('400'),
    404: t('404'),
    500: t('500'),
  }
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  useEffect(() => {
    // if this page mounts without a return,
    // attempt to fetch orders with the
    // token
    if (customer.orders.length === 0) {
      dispatch(fetchOrders({
        onError: (error, _dispatch) => {
          if (runtime == "return-portal") {
            let errorMessage = t('wereHavingIssues');
            if (error?.response?.status && errorCopies[error.response.status]) {
              errorMessage = errorCopies[error.response.status]
            }
            _dispatch(handlePortalPurchasesError(errorMessage))
          }
        }
      }));
    }
    // clear out dropoff method in case  the user
    // is coming back from the dropoff method page
    if (runtime === "return-portal") {
      dispatch(setSelectedDropoffMethod(null));
    }
  }, []);

  useEffect(() => {
    if (customer.orders.length > 0) {
      statsigClient.initialize({
        email: getCustomerEmailFromPurchases(customer.orders),
        retailerID: claims?.returnsApp?.retailerID,
        environment: window?.appConfig?.HR_ENVIRONMENT ?? "local",
        locale: app.locale,
        returnSessionID: claims.returnSessionID,
      });
    }
  }, [customer.orders]);

  // return shopping - if no items are marked for refund
  useEffect(() => {
    if (!containsReturn(itemsMarkedForCompletion)) {
      dispatch(setPurchasing(undefined));
    }
  }, [itemsMarkedForCompletion]);

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    });
    ga.sendPageDetails(AnalyticsPageRoutes.ItemList, AnalyticCategories.ItemListPage);
  }, []);
  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HELPERS

  // checks if the instance.purchase ID belongs to one of the
  // itemsMarkedForCompletion array elements.
  // returns the itemsMarkedForCompletion idx
  // (or -1 if the instance is not present in the array)
  const getCompletedInstanceIdx = (instance: Instance) => {
    return customer.itemsMarkedForCompletion.findIndex((completeInstance: Instance) => {
      return instance.purchase?.id === completeInstance?.purchase?.id;
    })
  }

  // checks to see if item can be returned via admin mode or override
  // an override is necessary and will be requested if the item is:
  //  - under final sale
  //  - past return window
  // overrides are only allowed at in-store return bars

  //---------------------------------------------------------------------------
  //---------------------------------------------------------------------------
  // HANDLERS

  const itemIsReturnable = useMemo(() =>
    getItemIsReturnableFn({
      isAdminMode,
      isRetailer,
      runtime,
      setIsOverrideModalOpen,
      setFulfillOverride
    }), [isAdminMode, isRetailer, runtime, setIsOverrideModalOpen, setFulfillOverride])

  // clones the instance and promotes it to the selected
  // instance in preparation of the user choosing return
  // method/reason for the given instance in a new return
  const onInstanceClicked = async (instance: Instance) => {

    // check if item is returnable and whether an override was used
    const {returnable, overridden} = await itemIsReturnable(instance)
    if (!returnable) {
      //item is not clickable
      ga.event({
        category: AnalyticCategories.ItemListPage,
        action: ItemListPageActions.GreyItem,
        label: instance?.purchase?.returnability
      })
      return
    }

    const selectedInstance = cloneDeep(instance) as Instance;

    // mark instance as overriden if the return is an override
    if (overridden){
      selectedInstance.overridden = true
    }
    dispatch(setSelectedInstance(selectedInstance));
    lifecycle.advance();
    if (isAdminMode) {
      //send the returnability if it's set (When item is greyed out)
      //if not set, then label is not send by default
      ga.event({
        category: AnalyticCategories.ItemListPage,
        action: ItemListPageActions.Item,
        label: instance?.purchase?.returnability
      })
    } else {
      ga.event({
        category: AnalyticCategories.ItemListPage,
        action: ItemListPageActions.Item
      })
    }
  }

  const onCompletedInstanceDeselect = (id?: string) => {
    dispatch(deselectInstanceWithID(id));
    ga.event({
      category: AnalyticCategories.ItemListPage,
      action: ItemListPageActions.DeselectItem
    })
  }

  const onNextStep = () => {
    lifecycle.advance("nextStep");
  }
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING

  // purchases have been mapped to instances after
  // consuming the data from the /purchases call to allow
  // easier read/write when building a return from scratch
  const renderOrderPurchases = (purchases) => {
    return purchases.map((instance: Instance) => {
      const completedIdx = getCompletedInstanceIdx(instance);
      return (
        <InstanceButton
          item={completedIdx != -1 ? customer.itemsMarkedForCompletion[completedIdx] as Instance : instance}
          isSelected={completedIdx != -1}
          isDropship={page?.pageSettings?.isDropship}
          vendors={page?.pageSettings?.vendors}
          currentBrand={customer.currentBrand}
          currentOrder={customer.currentOrder}
          singleOrderReturns={app.singleOrderReturns}
          key={instance.purchaseID}
          runtime={app.runtime}
          // disable the return reason page for an instance if it's already marked for completion
          onClick={() => completedIdx === -1 && onInstanceClicked(instance)}
          onDeselect={() => onCompletedInstanceDeselect(instance.purchase?.id)}
          // if the item has been marked, use the completed version of the instance within the
          // itemsMarkedForCompletion array to get refund/return info pertaining to an instance
          onZoom={() => setInstanceDetailsModalInstance(instance)}
          retailerOptionLabels={app.copies?.storeCreditLabel ? { "store-credit": app.copies.storeCreditLabel } : {}}
          dataCyString={DataCyStrings.instanceButton}
        />
      );
    })
  }

  // Takes an array of orders and renders the entire purchase
  // list in the form of InstanceButtons sorted by order.
  const renderInstancesByOrders = () => {
    return customer?.orders?.map((order, idx) => {
      const purchaseOrderNumber = order?.orderNumber?.toString().replace(/^#+/, "") || "";

      return (
        <div
          key={purchaseOrderNumber}
          className={`order list ${idx === 0 ? "first" : ""}`}
          data-cy={DataCyStrings.orderList}
        >
          <div className="order-info">
            <div className="order-number" data-cy={DataCyStrings.orderNumber}>
              <h2>{t("orderNumber", { orderNumber: purchaseOrderNumber })}</h2>
            </div>
            <div className="order-date" data-cy={DataCyStrings.orderDate}>
              {getReadableTimestamp(order.when, app?.locale)}
            </div>
          </div>
          <$ScreenReaderOnlyComponent as="h3">{t('items')}</$ScreenReaderOnlyComponent>
          {renderOrderPurchases(order.purchases)}
        </div>
      );
    });
  };

  const baseComponentsByRuntime = {
    "return-portal": OrderListReturnPortalBase
  }

  const BaseComponent = baseComponentsByRuntime[app?.runtime] ?
    baseComponentsByRuntime[app.runtime] :
    $OrderList

  // used by non return portal runtimes.
  // XXX - if non return portal runtimes require localization
  // the translations from return portal base should be moved
  // to this file to promote shareability
  const footerInfo = {
    count: customer.itemsMarkedForCompletion.length,
    runtime: app.runtime
  }

  const nextPage = returnShoppingEnabled && containsReturn(itemsMarkedForCompletion) ? "returnShopping" : "nextSteps"

  return (
    <BaseComponent
      page={page}
      footer="return-count"
      // footer should advance to next steps for return portal
      advance={() => {
        ga.event({
          category: AnalyticCategories.ItemListPage,
          action: CommonPageActions.NextStep
        })
        lifecycle.advance(nextPage)
      }}
    >
      {app?.copies?.itemListMessage && <InfoMessage message={app.copies.itemListMessage} data-cy={DataCyStrings.itemListMessage}/>}
      {runtime === AppRuntimes.returnista && // only display copy to in-store returnista users
        <div className="reminder">{t('orderListReminderCopy')}</div>
      }
      {/* display query when value is an email */}
      {customer?.query?.includes("@") && (
      <div className="order-list-header">
        <div className="description">{t('ordersFor')}</div>
        <div className="query">{customer.query}</div>
      </div>
      )}
      <div className="orders" data-cy={DataCyStrings.orderListDisplay}>
        {renderInstancesByOrders()}
      </div>
      {/* XXX
        the return count footer is rendered inside the base container as a child in non
        return portal runtimes while return portal renders the footer adjacently to the
        BaseContainer contents to account for responsive styling
       */}
      {customer.itemsMarkedForCompletion.length > 0 && app.runtime != "return-portal" &&
        <div className="footer">
          <ReturnCountFooter {...footerInfo}>
            <PrimaryButton
              label={t('nextStep')}
              disabled={customer.itemsMarkedForCompletion.length <= 0}
              onButtonClick={onNextStep}
              width="121px"
              dataCyString={DataCyStrings.nextStepButton}
            />
          </ReturnCountFooter>
        </div>
      }
      {instanceDetailsModalInstance != undefined &&
        <InstanceDetailsModal
          instance={instanceDetailsModalInstance}
          setInstance={setInstanceDetailsModalInstance}
          isDropship={page?.pageSettings?.isDropship}
        />
      }
      <ReturnOverrideModal
          isOpen={isOverrideModalOpen}
          onCompletion={(result) => {
            setIsOverrideModalOpen(false)
            fulfillOverride?.(result)
          }}
          runtime={runtime}
      />
    </BaseComponent>
  );
  //----------------------------------------------------------------------------
}

// Because the order list contents are identical to other runtimes,
// we use this wrapper strictly for return portal in order to tweak
// styles as well as wrapping the contents in the return portal page
// template via ReturnPortalBaseWithPage
const OrderListReturnPortalBase = ({ page, children, footer, advance }) => {
  return (
    <ReturnPortalBaseWithPage page={page} footer={footer} advance={advance}>
      <$OrderListReturnPortal>
        {children}
      </$OrderListReturnPortal>
    </ReturnPortalBaseWithPage>
  )
}

export default OrderList;
